// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cotizacion-js": () => import("./../../../src/pages/cotizacion.js" /* webpackChunkName: "component---src-pages-cotizacion-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-politica-de-privacidad-js": () => import("./../../../src/pages/politica-de-privacidad.js" /* webpackChunkName: "component---src-pages-politica-de-privacidad-js" */),
  "component---src-pages-sellado-techo-comercial-industrial-js": () => import("./../../../src/pages/sellado-techo-comercial-industrial.js" /* webpackChunkName: "component---src-pages-sellado-techo-comercial-industrial-js" */),
  "component---src-pages-sobre-nosotros-js": () => import("./../../../src/pages/sobre-nosotros.js" /* webpackChunkName: "component---src-pages-sobre-nosotros-js" */),
  "component---src-pages-solicitud-enviada-js": () => import("./../../../src/pages/solicitud-enviada.js" /* webpackChunkName: "component---src-pages-solicitud-enviada-js" */)
}

